import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./hero.css"

const Content = ({
  title,
  subtitle,
  email,
  contentHeading,
  contentParagraph,
}) => {
  return (
    <article>
      <h1>{title}</h1>
      <h2>
        {subtitle} <a href={`mailto:${email}`}>{email}</a>
      </h2>
      <p>
        {contentHeading} {contentParagraph}
      </p>
    </article>
  )
}

export default () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subtitle
            email
            contentHeading
            contentParagraph
          }
        }
      }
    `
  )

  return (
    <main>
      <Content {...siteMetadata} />
    </main>
  )
}
