import React from "react"
import SEO from "../components/seo"
import Hero from "../components/hero"
import "./index.css"

const IndexPage = () => (
  <>
    <SEO
      title="Home"
      keywords={[
        "ventures",
        "strategies",
        "investments",
        "liquidity solutions",
        "corporate finance",
      ]}
    />
    <Hero />
  </>
)

export default IndexPage
